<script>
  import { onMount } from 'svelte';

  import CSSField from './CodeMirror.svelte';
  import JSONField from './CodeMirror.svelte';
  import ObjectField from './CodeMirror.svelte';

  export let id;
  export let type;
  export let defaultValue;
  export let required;
  export let value;

  $: value = value ? value : defaultValue;
  const CSSOptions = type == 'css' && {
    lineNumbers: true,
    border: true,
    theme: 'dracula',
    mode: 'css',
    gutters: ['CodeMirror-lint-markers'],
    styleActiveLine: true,
    lint: true,
    value: (value && value.code) || '',
  };

  const JSONOptions = window &&
    type == 'json' && {
      lineNumbers: true,
      border: true,
      theme: 'dracula',
      mode: 'application/json',
      gutters: ['CodeMirror-lint-markers'],
      styleActiveLine: true,
      lint: true,
      value: value && value.code ? value.code : '{}',
    };
  const ObjectOptions = window &&
    type == 'object' && {
      mode: 'javascript',
      theme: 'dracula',
      json: true,
      lineNumbers: true,
      border: true,
      gutters: ['CodeMirror-lint-markers'],
      styleActiveLine: true,
      lint: true,
      value: [undefined, null, ''].includes(value) ? '{}' : JSON.stringify(value, null, 2),
    };
  function typeAction(node) {
    if (type == 'list') {
      node.type = 'text';
    } else {
      node.type = type;
    }
  }
  function change(n) {
    if (type == 'object') {
      try {
        value = JSON.parse(n.detail);
      } catch (error) {}
    } else {
      value = { code: n.detail, lang: type };
    }
  }
  function convertToArray() {
    value = typeof value == 'string' ? value.split(',') : value;
  }
  function typeCheck() {
    value = typeof value == 'string' && type == 'number' ? parseFloat(value) : value;
  }
</script>

<label for={id} class:checkboxLabel={type == 'checkbox'} class:editorLabel={type == 'css' || type == 'json' || type == 'object'}>
  <span> {id}</span>
  {#if type == 'list'}
    <input class:hidden={['json', 'css', 'object'].includes(type)} {id} use:typeAction bind:value on:input={convertToArray} {required} />
  {:else if type == 'checkbox'}
    <input {id} type="checkbox" bind:value bind:checked={value} {required} on:input={typeCheck} />
  {:else}
    <input
      class:hidden={['json', 'css', 'object'].includes(type)}
      {id}
      use:typeAction
      bind:value
      {required}
      step="0.01"
      on:input={typeCheck}
    />
  {/if}

  {#if type == 'css'}
    <!-- <textarea bind:value={value.code} /> -->
    <CSSField on:change={change} options={CSSOptions} class="editor" />
  {/if}

  {#if type == 'json'}
    <JSONField on:change={change} options={JSONOptions} class="editor" />
  {/if}
  {#if type == 'object'}
    <ObjectField on:change={change} options={ObjectOptions} class="editor" />
  {/if}
</label>

<style>
  :global(.editor) {
    line-height: 1.2rem;
    font-weight: 300;
    font-family: monospace;
    margin-bottom: 1em;
    overflow: hidden;
    border-radius: 0 4px 4px 4px;
    width: 100%;
    font-size: 14px;
  }
  :global(.editorLabel) {
    order: 3 !important;
  }
  :global(.hidden) {
    display: none !important;
  }

  label > span {
    font-weight: 600;
    color: rgb(122, 130, 145);
    font-size: 12px;
    text-transform: uppercase;
  }
  label:not(.checkboxLabel) span {
    background-color: rgb(223, 223, 227);
    border: 0px;
    border-radius: 3px 3px 0px 0px;
    padding: 3px 6px 2px;
  }
  input:not(input[type='checkbox']) {
    width: 100%;
    display: block;
    padding: 16px 20px;
    margin: 0px;
    border: 2px solid rgb(223, 223, 227);
    border-radius: 0px 5px 5px;
    outline: 0px;
    box-shadow: none;
    background-color: rgb(255, 255, 255);
    color: rgb(68, 74, 87);
    transition: border-color 0.2s ease 0s;
    position: relative;
    font-size: 15px;
    line-height: 1.5;
    margin-bottom: 1em;
  }
  :global(input[type='checkbox']) {
    width: 30px;
    height: 30px;
    display: inline;
    margin-bottom: 1em;
  }
  label:not(.checkboxLabel) {
    order: 2;
    flex-basis: 100%;
    max-width: 100%;
  }
  .checkboxLabel {
    background: none;
    display: inline-flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    order: 1;
    flex: 0;
    flex-basis: 50%;
  }
  .checkboxLabel span {
    display: inline-block;
    margin-bottom: 8px;
  }
</style>
