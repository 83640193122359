<script>
  import Input from '../Components/Input.svelte';
  import { navigate } from 'svelte-routing';
  import { platforms } from '../store';
  import { fetchPlatforms, updateClientConfig } from '../api';
  let newClient;
  function displayClient(cid) {
    navigate(`/platforms/${cid}`);
  }
  async function createNewClient() {
    await updateClientConfig('platforms', newClient, {});
    navigate(`/platforms/${newClient}`);
    newClient = null;
  }
  fetchPlatforms();
</script>

<h1 class="main-title">Platforms</h1>
<div class="row">
  <div>
    <Input id="Platform unique id" type="text" name="newClient" bind:value={newClient} />
  </div>
  <button class="primary" on:click={() => createNewClient()}>Add platform</button>
</div>
<ul>
  <!-- <li style="	color: var(--s-c);width:100%;" on:click={() => displayClient('staging')}>Staging</li> -->
  {#each $platforms as client}
    <li on:click={() => displayClient(client.cid)}>
      {client.url ? client.url : client.cid}
    </li>
  {:else}
    <p>loading..</p>
  {/each}
</ul>

<style>
  .row {
    display: flex;
    align-items: flex-end;
    margin-bottom: 2em;
  }
  .row > div {
    flex: 1;
  }
  .row button {
    height: 58px;
    margin-top: 3px;
  }
  :global(.row input) {
    margin-bottom: 0 !important;
  }
  ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    flex-wrap: wrap;
  }
  li {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
    min-width: 47%;
    border: 1px solid #efefef;
    box-sizing: border-box;
    box-shadow: 0px -1px 12px rgba(208, 208, 210, 0.16);
    border-radius: 4px;
    margin: 0 10px 20px;
    padding: 4px 12px;
    cursor: pointer;
    color: var(--l-c);
    background-color: var(--bg-c);
    font-weight: bold;
  }
</style>
