import { writable } from 'svelte/store';

export const user = writable(JSON.parse(localStorage.getItem('user')) || {});
export const userPermisions = writable(
  JSON.parse(localStorage.getItem('userPermisions')) || {
    admin: false,
  }
);
export const isLoggedIn = writable(localStorage.getItem('isLoggedIn') || false);
export const configType = writable('frontend');
export const clientOpen = writable(null);
export const clients = writable([]);
export const platforms = writable([]);
export const currentClient = writable(null);
export const currentClientHistory = writable(null);
export const configModal = writable(false);
export const currentClientHistoryList = writable([]);

isLoggedIn.subscribe((val) => (localStorage.isLoggedIn = val));
user.subscribe((val) => (localStorage.user = JSON.stringify(val)));
userPermisions.subscribe((val) => (localStorage.userPermisions = JSON.stringify(val)));
export const defaultCarouselConfig = {
  buttonStyle: 'minimal',
  itemCount: 5,
  headingAlignment: 'left',
  headingFontFamily: 'sans-serif',
  bodyFontFamily: 'serif',
  customCss: { code: '' },
};
