<script>
  import { currentClientHistory, currentClient, configModal } from '../store';
  function syntaxHighlight(json) {
    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return json.replace(
      /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
      function (match) {
        var cls = 'number';
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = 'key';
          } else {
            cls = 'string';
          }
        } else if (/true|false/.test(match)) {
          cls = 'boolean';
        } else if (/null/.test(match)) {
          cls = 'null';
        }
        return '<span class="' + cls + '">' + match + '</span>';
      }
    );
  }

  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();

  function replaceCurrentConfig() {
    dispatch('replaceCurrentConfig');
  }

  $: if ($currentClientHistory && $configModal === true && document.querySelector('.sb-modal pre')) {
    document.querySelector('.sb-modal pre').innerHTML = syntaxHighlight(
      JSON.stringify($currentClientHistory, undefined, 2)
    );
  }
</script>

{#if $configModal == true}
  <div class="sb-modal">
    <h2>Replace config with:</h2>
    <pre />
    <button
      class="cancel"
      on:click={() => {
        $configModal = false;
      }}>Close</button
    >
    <button on:click={replaceCurrentConfig}>Replace</button>
  </div>
{/if}

<style>
  .sb-modal {
    width: 60%;
    height: 700px;
    background-color: #fff;
    border: 1px solid #efefef;
    box-sizing: border-box;
    box-shadow: 0px -1px 12px rgba(208, 208, 210, 0.36);
    border-radius: 4px;
    position: fixed;
    top: 10%;
    left: 20%;
    overflow: scroll;

    z-index: 2134;
    padding: 1em;
  }
  .sb-modal pre {
    padding: 0.5em 1em;
    border-radius: 4px;
    overflow-x: scroll;
  }
  h2 {
    margin-top: 0;
  }
</style>
