<script>
  import Dashboard from './routes/Dashboard.svelte';
  import EmbedConfig from './routes/EmbedConfig.svelte';
  import CarouselConfigurator from './routes/CarouselConfigurator.svelte';
  import Configs from './routes/Configs.svelte';
  import Platforms from './routes/Platforms.svelte';
  import Notification from './Components/notification/Index.svelte';
  import ClientConfig from './routes/ClientConfig.svelte';
  import PlatformConfig from './routes/PlatformConfig.svelte';
  import { Router, Link, Route, navigate } from 'svelte-routing';
  import Login from './routes/Login.svelte';
  import { user, isLoggedIn, userPermisions } from './store.js';
  import { fetchFrontendClients } from './api.js';
  import { auth } from './firebase.js';
  import { onMount } from 'svelte';
  import { doc, getDoc, setDoc, getFirestore } from 'firebase/firestore';
  const classActive = 'active';
  const classInactive = 'inactive';
  import GoDashboard from 'svelte-icons/go/GoDashboard.svelte';
  import EmbedConfigGtm from './routes/EmbedConfigGtm.svelte';
  let url;
  const handleLogOut = () => {
    auth.signOut().then(
      function () {
        // Sign-out successful.

        isLoggedIn.set(false);
        user.set({});
        navigate('/login');
        console.log('logout', $user);
      },
      function (error) {
        // An error happened.
        console.warn('error on logout', error.message);
      }
    );
  };

  function getProps({ location, href, isPartiallyCurrent, isCurrent }) {
    if (isCurrent) {
      url = href.replace('/', '');
    }
    const isActive = href === '/' ? isCurrent : isPartiallyCurrent || isCurrent;
    // The object returned here is spread on the anchor element's attributes
    if (isActive) {
      return { class: classActive };
    }
    return { class: classInactive };
  }

  async function fetchUserPermisions() {
    if ($user.uid) {
      const db = getFirestore();
      const docRef = doc(db, 'users', $user.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        $userPermisions = docSnap.data();
        console.log('fetchUserPermisions', $userPermisions);
      } else {
        setDoc(docRef, { admin: false });
      }
    }
  }

  $: if ($isLoggedIn === true || $isLoggedIn === 'true') {
  } else {
    if (window.location.pathname.includes('embed') == false) navigate('/login');
  }
  let asideExpanded = false;
  onMount(async () => {
    await fetchUserPermisions();
    await fetchFrontendClients();

    url = window.location.pathname.replace('/', '');
    sessionStorage.isNewSession = true;
  });
</script>

<Notification />
<svelte:head>
  {#if $isLoggedIn == true || $isLoggedIn == 'true'}
    <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" />
  {/if}
</svelte:head>
<Router {url}>
  <aside class:sb-aside-small={asideExpanded == false}>
    <nav>
      <div class="menuButton" title="Toggle Menu" on:click={() => (asideExpanded = !asideExpanded)}>
        {#if !asideExpanded}
          <img width="20px" src="/shopbox.svg" alt="logo" />
        {:else}
          <img height="32x" src="https://www.shopbox.ai/_app/assets/shopbox-logo-3758d420.svg" alt="logo" />
        {/if}
      </div>
      {#if $isLoggedIn == true || $isLoggedIn == 'true'}
        <span>menu</span>
        <Link to="dashboard" {getProps} title="Dashboard"><i class="bx bxs-dashboard" /><b>Dashboard</b></Link>
        {#if $userPermisions.admin == true}
          <span>admin</span>
          <Link to="platforms" {getProps} title="Platforms"><i class="bx bx-cog" /><b>Platforms</b></Link>
          <Link to="configs" title="Configs" {getProps}><i class="bx bxs-cog" /><b>Configs</b></Link>
          <Link to="carouselconfigurator" title="Carousels" {getProps}><i class="bx bx-carousel" /> <b>Carousels</b></Link>
        {/if}
        <div class="sb-spacer" />
        <a class="inactive" title="Log out" href="/" on:click={handleLogOut}><i class="bx bx-log-out" /><b>Logout</b></a>
      {:else}
        <Link to="login" {getProps}><i class="bx bx-log-in" /> <b>Login</b></Link>
      {/if}
    </nav>
  </aside>
  <main class={`sb-${url}`}>
    {#if $isLoggedIn === true || $isLoggedIn === 'true'}
      {#if $userPermisions.admin == true}
        <Route path="configs" component={Configs} />
        <Route path="/configs/:id" let:params>
          <ClientConfig id={params.id} />
        </Route>
        <Route path="platforms" component={Platforms} />
        <Route path="/platforms/:id" let:params>
          <PlatformConfig id={params.id} />
        </Route>
      {/if}

      <Route path="dashboard" component={Dashboard} />
    {/if}
    <Route path="login" component={Login} />
    <Route path="/embed/:id" let:params>
      <EmbedConfig id={params.id} />
    </Route>
    <Route path="/embed/gtm/:id" let:params>
      <EmbedConfigGtm id={params.id} />
    </Route>
  </main>
</Router>

<style>
  :global(body) {
    display: flex;
  }
  a {
    color: var(--p-c);
  }
  .sb-spacer {
    flex: 1;
  }
  aside {
    height: 100vh;
    padding: 1em 0;
    width: 194px;
  }

  aside nav {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .sb-aside-small {
    width: 80px;
    padding: 0.2em;
    padding-right: 16px !important;
    padding-bottom: 3em !important;
  }
  .sb-aside-small b,
  .sb-aside-small span {
    display: none;
  }
  :global(.sb-aside-small nav a),
  .sb-aside-small .menuButton {
    justify-content: center;
    padding: 1em 0;
    margin: 0.2em;
  }
  :global(.sb-aside-small nav i),
  .sb-aside-small .menuButton img {
    position: relative;
    left: 0;
  }

  :global(aside nav a) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0.4em 1em;
    padding: 0.8em;
    background-color: var(--bg-c);
    padding-left: 3em;
    color: var(--p-c);
    text-decoration: none !important;
    border-radius: 16px;
    transition: all 240ms ease-out;
    position: relative;
  }
  :global(.sb-dashboard) {
    overflow: hidden !important;
  }
  .menuButton {
    background-color: var(--bg-c);
    color: var(--p-c);
    border-radius: 16px;
    height: 44px;
    padding-left: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.4em 1em;
    margin-bottom: 3em !important;
    position: relative;
    cursor: pointer;
  }
  :global(aside nav a):hover,
  .menuButton:hover {
    background-color: var(--hvr-c);
    color: var(--w-c) !important;
  }
  .menuButton:hover img {
    filter: brightness(0) invert(1);
  }
  :global(aside nav i) {
    position: absolute;
    left: 1em;
  }
  .menuButton img {
    position: absolute;
    left: 1em;
  }
  aside nav span {
    padding-left: 1em;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 2px;
    opacity: 0.6;
    margin: 2.5em 1em 0.2em;
  }
  main {
    height: 100%;
    width: 100%;
    flex: 1;
    padding: 1em;
    background-color: var(--w-c);
    border: 1px solid #efefef;
    box-shadow: 0px -1px 12px rgba(208, 208, 210, 0.16);
    border-radius: 16px;
    overflow: scroll;
    overflow-x: hidden;
  }
  :global(body) {
    padding: 16px;
    overflow: hidden;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #efefef;
  }

  ::-webkit-scrollbar {
    width: 8px;
    background-color: #efefef;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;

    background-color: #ddd;
  }

  .icon {
    margin: 0 auto;
    width: 30px;
  }

  :global(button, .button) {
    border: 0px;
    border-radius: 16px;
    cursor: pointer;
    box-shadow: rgb(68 74 87 / 5%) 0px 4px 12px 0px, rgb(68 74 87 / 15%) 0px 1px 3px 0px;
    font-weight: 500;
    background-color: var(--bg-c);
    color: var(--l-c);
    padding: 0.8em 1.6em;
    min-width: 120px;
    text-decoration: none !important;
    transition: all 240ms ease-out;
    font-weight: bold;
  }

  :global(.button-wrap) {
    text-align: right;
  }
  :global(.header-controls) {
    display: flex;
    gap: 1em;
    border-bottom: 1px solid #efefef;
    padding: 0 1em 1em;
    margin: 0em -1em 2em;
  }

  :global(button.primary, .button.primary) {
    background-color: var(--p-c) !important;
    color: var(--bg-c);
  }

  :global(button.cancel, .button.cancel) {
    color: var(--s-c) !important;
    background-color: var(--bg-c) !important;
  }

  :global(button:hover, .button:hover) {
    color: var(--bg-c) !important;
    background-color: var(--hvr-c) !important;
  }
  :global(button.cancel:hover, .button.cancel:hover) {
    background-color: var(--s-c) !important;
    color: var(--bg-c) !important;
  }
</style>
