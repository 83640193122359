import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyC2So0YnRKw9K80PBNllgcgttQ7v_mjPlA',
  authDomain: 'sb-portal-d3dc9.firebaseapp.com',
  projectId: 'sb-portal-d3dc9',
  storageBucket: 'sb-portal-d3dc9.appspot.com',
  messagingSenderId: '1054990353885',
  appId: '1:1054990353885:web:935c15541a0a464d6ae234',
  measurementId: 'G-P8KHH1T8FL',
};

initializeApp(firebaseConfig);

export const auth = getAuth();
export const provider = new GoogleAuthProvider();
