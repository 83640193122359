<script>
  import Input from '../Components/Input.svelte';
  import { navigate } from 'svelte-routing';
  import { clients } from '../store';
  import { fetchFrontendClients, updateClientConfig } from '../api';
  let newClient;
  function displayClient(cid) {
    navigate(`/configs/${cid}`);
  }
  async function createNewClient() {
    await updateClientConfig('frontend', newClient, { cid: newClient });
    await updateClientConfig('modelling', newClient, {});
    await updateClientConfig('backend', newClient, {});
    await updateClientConfig('app-configs', newClient, {});
    await updateClientConfig('reporting', newClient, {});
    navigate(`/configs/${newClient}`);
    newClient = null;
  }
  fetchFrontendClients();
</script>

<h1 class="main-title">Clients</h1>
<div class="row">
  <div>
    <Input id="Client unique id" type="text" name="newClient" bind:value={newClient} />
  </div>
  <button class="primary" on:click={() => createNewClient()}>Create Client</button>
</div>
<ul>
  <li style="	color: var(--s-c);width:100%;" on:click={() => displayClient('staging')}>Staging</li>
  {#each $clients as client}
    <li on:click={() => displayClient(client.cid)}>
      <span>{client.cid}</span>
      <span> {client.client_name ? client.client_name : 'undefined'}</span>
    </li>
  {:else}
    <p>loading..</p>
  {/each}
</ul>

<style>
  .row {
    display: flex;
    align-items: flex-end;
    margin-bottom: 2em;
  }
  .row > div {
    flex: 1;
  }
  .row button {
    height: 58px;
    margin-top: 3px;
  }
  :global(.row input) {
    margin-bottom: 0 !important;
  }
  ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    flex-wrap: wrap;
  }
  li {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 40px;
    min-width: 22%;
    border: 1px solid #efefef;
    box-sizing: border-box;
    box-shadow: 0px -1px 12px rgba(208, 208, 210, 0.16);
    border-radius: 4px;
    margin: 0 10px 20px;
    padding: 8px 12px;
    cursor: pointer;
    color: var(--l-c);
    background-color: var(--bg-c);
    font-weight: bold;
  }
  li span:first-of-type {
    font-size: 12px;
    color: var(--t-c);
    font-weight: normal;
  }
  li span:last-of-type {
    font-size: 1.2em;
  }
</style>
