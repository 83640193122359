<script>
  import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
  import { auth, provider } from '../firebase';
  import { user, isLoggedIn } from '../store';
  import { navigate } from 'svelte-routing';
  import { notifications } from '../Components/notification/notifications.js';
  // let email = 'admin@admin.com';
  // let password = 'admin1234';
  let email = null;
  let password = null;
  let resetForm = false;
  const login = async () => {
    try {
      const res = await signInWithEmailAndPassword(auth, email, password);

      $user.uid = res.user.uid;
      $isLoggedIn = true;
      navigate('/dashboard');
    } catch (err) {
      console.error(err);
    }
  };
  function resetPassword() {
    if (email) {
      sendPasswordResetEmail(auth, email)
        .then(function () {
          notifications.success(`Password reset email sent!`, 1000);
          resetForm = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }
</script>

<div class="login-wrapper">
  <div class="get-in-touch">
    <img src="https://www.shopbox.ai/_app/assets/shopbox-logo-3758d420.svg" alt="logo" />
    <h1>Don't have an <br /> account?</h1>
    <a href="https://www.shopbox.ai/contact/" target="_blank" class="button"> Get in touch</a>
  </div>

  {#if resetForm}
    <form on:submit|preventDefault={resetPassword}>
      <h1 class="main-title">Reset Password</h1>
      <input type="email" bind:value={email} required placeholder="Email" />
      <div class="btn-row">
        <button class="primary"> Reset </button>
        <button class="cancel" on:click={() => (resetForm = !resetForm)}>Cancel</button>
      </div>
    </form>
  {:else}
    <form on:submit|preventDefault={login}>
      <h1 class="main-title">Login to Dashboard</h1>
      <input type="email" bind:value={email} required placeholder="Email" />
      <input type="password" bind:value={password} required placeholder="Password" />
      <div class="btn-row">
        <button class="primary"> Login </button>
        <span style="  cursor: pointer;" on:click={() => (resetForm = !resetForm)}>Reset password</span>
      </div>
    </form>
  {/if}
</div>

<style>
  :global(.sb-login) {
    padding: 0 !important;
  }
  .login-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100%;
  }
  .login-wrapper > * {
    padding: 3em;
  }
  .get-in-touch {
    height: 100%;
    background-color: var(--bg-c);
    flex-basis: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .btn-row {
    display: flex;
    justify-content: space-between;
  }
  .get-in-touch img {
    width: 50%;
    margin-bottom: 2em;
  }
  .get-in-touch h1 {
    font-weight: normal;
    font-size: 2em;
    margin-bottom: 1em;
    text-align: center;
  }
  .login-wrapper form {
    flex-basis: 60%;
    padding: 12em;
  }
  input:not(input[type='checkbox']) {
    display: block;
    width: 100%;

    padding: 16px 20px;
    margin: 0px;
    border: 2px solid rgb(223, 223, 227);
    border-radius: 0px 5px 5px;
    outline: 0px;
    box-shadow: none;
    background-color: rgb(255, 255, 255);
    color: rgb(68, 74, 87);
    transition: border-color 0.2s ease 0s;
    position: relative;
    font-size: 15px;
    line-height: 1.5;
    margin-bottom: 1em;
  }
</style>
