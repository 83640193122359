<script>
  import { notifications } from '../Components/notification/notifications.js';
  import { configType, currentClient, configModal, currentClientHistoryList, currentClientHistory } from '../store';
  import { updateClientConfig, fetchClientHistory, fetchClient } from '../api';
  import Input from '../Components/Input.svelte';
  import ModalHistory from '../Components/ModalHistory.svelte';
  import { collection, getFirestore, getDocs } from 'firebase/firestore';
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';

  export let id;
  let selectedHistory = '';
  let mounted = false;
  let data = {};
  $: fields = [];
  $: client = id;

  const removeEmpty = (source) => {
    let obj = Object.assign({}, source);
    let ignoredKeys = ['locale', 'embedstyles', 'carouselconfig'];
    Object.entries(obj).forEach(
      ([key, val]) =>
        (val && !ignoredKeys.includes(key) && typeof val === 'object' && removeEmpty(val)) ||
        ((val === null || val === '') && delete obj[key])
    );
    return obj;
  };

  async function fetchConfigs() {
    const db = getFirestore();
    const path = 'configs/' + $configType;
    const q = collection(db, path + '/fields');
    let querySnapshot = await getDocs(q);
    let tempFields = [];
    querySnapshot.forEach((doc) => {
      tempFields = [{ ...doc.data(), id: doc.id }, ...tempFields];
    });
    fields = tempFields;
  }

  async function save() {
    let unflat = removeEmpty(data);
    await updateClientConfig($configType, client, unflat);
    navigate('/configs');
    notifications.success(`Saved config: <b>${$configType}</b> / <b>${client}</b> !`, 1000);
  }
  async function selectHistory(e) {
    $configModal = true;
    await fetchClient($configType, client, selectedHistory);
  }
  async function selectType(e) {
    mounted = false;
    fields = [];
    data = {};
    $currentClientHistoryList = [];
    await fetchConfigs();
    await fetchClient($configType, client);
    fetchClientHistory($configType, client);
    populateData();
    mounted = true;
  }
  function populateData() {
    $currentClient = $currentClient;

    fields.forEach((field) => {
      data[field.id] = field.defaultValue;
      data[field.id] = $currentClient[field.id];
    });
  }
  function replaceCurrentConfig() {
    mounted = false;
    $currentClient = $currentClientHistory;
    $configModal = false;

    setTimeout(() => {
      mounted = true;
      populateData();
    }, 300);
  }
  onMount(async () => {
    await fetchConfigs();
    await fetchClient($configType, client);
    fetchClientHistory($configType, client);
    populateData();

    mounted = true;
  });
</script>

<h1 class="main-title">
  <span>{$configType}</span>
  {client}
</h1>

<ModalHistory on:replaceCurrentConfig={replaceCurrentConfig} />

<div class="header-controls">
  <select bind:value={$configType} on:change={selectType}>
    <option value="" disabled selected> Select config type </option>

    <option value="frontend"> Front end </option>
    <option value="backend"> Back end </option>
    <option value="modelling"> Modelling </option>
    <option value="app-configs"> App Config </option>
    <option value="reporting"> Reporting </option>
  </select>

  {#if mounted && $configType != null && $currentClientHistoryList.length > 0 && typeof $currentClientHistoryList != 'string'}
    <select bind:value={selectedHistory} on:change={selectHistory}>
      <option value="" disabled selected> Select previous config version </option>

      {#each [...$currentClientHistoryList].reverse() as entity, i}
        {#if i == 0}
          <option value={entity.name}>
            <b>Latest</b> - {entity.datetime}
          </option>
        {:else}
          <option value={entity.name}>
            {entity.name} - {entity.datetime}
          </option>
        {/if}
      {/each}
    </select>
  {/if}
</div>

{#if mounted && $configType != null}
  <form action="">
    {#each fields as field, index}
      <Input {...field} bind:value={data[field.id]} />
    {:else}
      <h1>no field config found</h1>
    {/each}
  </form>
  <div class="button-wrap">
    <button class="cancel" on:click={() => navigate('/configs')}>Go back</button>
    <button class="primary" on:click={save}>Save changes</button>
  </div>
{/if}

<style>
  form {
    display: flex;
    flex-wrap: wrap;
  }
  h1 span {
    display: block;
    font-size: 0.4em;
    letter-spacing: -0.01em;
    color: var(--t-c-l);
    text-transform: uppercase;
  }
  select {
    padding: 16px 8px;
  }
</style>
