<script>
  import SvelteCopyUrlButton from 'svelte-copy-url-button';
  export let id;
  $: val = ` <script>
    document.addEventListener('DOMContentLoaded', () => {
      var sbid = '${id}';
      var t = document.createElement("shopbox-app-v2"),
        s = document.createElement("script");
      t.setAttribute('cid', sbid); s.type = "text/javascript";
      s.async = !0; s.src = "https://widget.shopbox.ai/js/app.js";
      document.getElementsByTagName("body")[0].append(t);
      document.getElementsByTagName("head")[0].append(s);
    })
</\script>`;
</script>

<h1 class="main-title">Shopbox embed</h1>
<textarea bind:value={val} />
<SvelteCopyUrlButton
  class="button"
  size="14"
  defaultText="Copy embed code"
  copiedText="Copied!"
  icon={false}
  timeout="1000"
  url={val}
/>

<style>
  textarea {
    height: 240px;
    width: 100%;
    border: 1px solid #ddd;
    font-size: 17px;
  }
</style>
