<script>
  import { getAuth } from 'firebase/auth';

  let userToken;

  const auth = getAuth();
  const user = auth.currentUser;
  if (user) {
    userToken = user.getIdToken();
  } else {
    auth.onAuthStateChanged(function (u) {
      if (u) {
        u.getIdToken().then(function (idToken) {
          userToken = idToken;
        });
      }
    });
  }
</script>

<!-- <h1 class="main-title">Dashboard</h1> -->

{#await userToken then token}
  <iframe
    title="Shopbox Superset"
    src="https://superset.shopbox.ai/login?standalone=true&token={token}"
    name="Superset stats"
    scrolling="yes"
    height="100vh"
    width="100%"
    style="border: none;"
  />
{/await}

<style>
  iframe {
    width: 100% !important;
    height: 100% !important;
  }
</style>
