import { platforms, clients, currentClient, currentClientHistory, currentClientHistoryList, isLoggedIn } from './store';
const apiBase = 'https://sb-app-configs-djgqciaueq-ew.a.run.app';

import { getAuth } from 'firebase/auth';
export const getToken = async () => {
  const auth = getAuth();
  const user = auth.currentUser;
  if (user) {
    return user.getIdToken();
  } else {
    auth.onAuthStateChanged(function (u) {
      if (u) {
        u.getIdToken().then(function (idToken) {
          return idToken;
        });
      }
    });
  }
};
export async function fetchPlatforms() {
  const userToken = `?token=${await getToken()}`;
  if (userToken == '?token=undefined') return;

  const url = apiBase + `/configs/platforms/list${userToken}`;
  const res = await fetch(url);

  const json = await res.json();
  if (res.ok) {
    platforms.set(json);
  } else {
    if (json.detail == 'Unathorized') {
      // isLoggedIn.set(false);
    }
  }
}
export async function fetchFrontendClients() {
  const userToken = `?token=${await getToken()}`;
  if (userToken == '?token=undefined') return;

  const url = apiBase + `/configs/backend/list${userToken}`;
  const res = await fetch(url);

  const json = await res.json();
  if (res.ok) {
    clients.set(json);
  } else {
    if (json.detail == 'Unathorized') {
      // isLoggedIn.set(false);
    }
  }
}
export async function fetchClient(type, client, fname) {
  const userToken = `?token=${await getToken()}`;
  if (userToken == '?token=undefined') return;
  let url = apiBase + `/configs/${type}/customer/${client}${userToken}`;
  if (fname) {
    url += `&fname=${fname}`;
  }

  const res = await fetch(url);

  const json = await res.json();
  if (res.ok) {
    if (fname) {
      currentClientHistory.set(json);
      // currentClient.set(json);
    } else {
      currentClient.set(json);
    }
  } else {
    if (json.detail == 'Unathorized') {
      // isLoggedIn.set(false);
    }
  }
}
export async function fetchClientHistory(type, client) {
  const userToken = `?token=${await getToken()}`;
  if (userToken == '?token=undefined') return;
  const url = apiBase + `/configs/${type}/list/${client}${userToken}`;
  const res = await fetch(url);

  const json = await res.json();
  if (res.ok) {
    currentClientHistoryList.set(json);
  } else {
    console.log(json);
  }
}
export async function updateClientConfig(type, client, config) {
  const userToken = `?token=${await getToken()}`;
  if (userToken == '?token=undefined') return;
  const url = apiBase + `/configs/${type}/customer/${client}${userToken}`;
  const res = await fetch(url, {
    method: 'post',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(config),
  })
    .then((res) => res.json())
    .then((res) => console.log(res));
}
